import * as React from 'react'
import { graphql, Link as GatsbyLink } from 'gatsby'
import InternalLayout from '../components/InternalLayout'
import { CustomLink } from '../utils/customLink'
import Seo from '../components/Seo'
import {
  Flex,
  Box,
  Heading,
  Text,
  Link
} from '@chakra-ui/react'
import { RichText } from 'prismic-reactjs'
import { getImage, GatsbyImage } from 'gatsby-plugin-image'
import Tetons from '../components/PageHero/Tetons'
import { withPrismicPreview } from 'gatsby-plugin-prismic-previews'
import Sidebar from '../components/Sidebar/Sidebar'
import MooseLottie from '../components/Footer/Moose/MooseLottie'
import PageNoHero from '../components/PageHero/PageNoHero'
import { ChevronRightIcon, ChevronLeftIcon } from '@chakra-ui/icons'

const NewsTemplate = (props) => {
      // Explicitly delcare props and destructure the other properties:
      const { location, data, pageContext } = props;
      const pageLocation = location.pathname;
      const { prev, next } = pageContext

  if (!data) return null; 

  const document = data.prismicNews.data;
  console.log(document)
  //const menuItems = null || document.sidebar_menu.document?.data.body;

  return (
    <InternalLayout>
      <Seo title={`City of Driggs | ${document.post_title.text}`}  />
      <PageNoHero
       bgColor="#667743"
      >
        <Flex w="100%" height="15vh" justifyContent="space-between" alignItems="flex-end">
            <Box px="4" zIndex="1" width="60%" alignSelf="center" marginTop="1rem">
              <Heading color="white" as="h1" fontSize={{base: '1rem', lg: '3rem'}} lineHeight="1" textTransform="uppercase">
              {document.post_title.text}
              </Heading>
            </Box>
            <Box width="40%">
            <Tetons 
              boxWidth="80%"
            />
            </Box>
          
        </Flex>
        
      </PageNoHero>
      <Box position="relative" zIndex="1">
      <Flex w="100%" h="100%" flexWrap="wrap" flexDirection={{base: 'column-reverse', md: 'row'}}>
            <Box w={{base: '100%', lg: '25%'}}>
              <Sidebar
                menuHeading={document.sidebar_menu?.document.data.menu_title.text} 
                //menuItems={menuItems}
                pageLocation={pageLocation}
              />
            </Box>
          <Box w={{base: '100%', lg: '75%'}} bg="white" p="8" mb={{base: '4rem', md: '36rem'}}>
            <Box mb="2" float="right" width="50%" pt="2" ml="2">
              <GatsbyImage image={getImage(document.featured_image.localFile)} />
            </Box>
            <Text>
          <strong>Published:</strong> {document.date_posted}
        </Text>
        <RichText render={document.post_content.richText} serializeHyperlink={CustomLink} />
        
        <Flex
            py="8"
            justifyContent="space-between"
            flexWrap="wrap"
            textAlign="center"
            style={{clear: 'both'}}
          >
            <Box mb={{ sm: 4, md: 0 }}>
              {prev && (
                <Link
                  to={`/news/${prev.uid}`}
                  as={GatsbyLink}
                  variant="blog-link"
                >
                  <ChevronLeftIcon fontSize="1.5rem" />
                  {prev.data.post_title.text}
                </Link>
              )}
            </Box>
            <Box mb={{ sm: 4, md: 0 }}>
              {next && (
                <Link
                  to={`/news/${next.uid}`}
                  as={GatsbyLink}
                  variant="blog-link"
                >
                  {next.data.post_title.text}
                  <ChevronRightIcon fontSize="1.5rem" />
                </Link>
              )}
            </Box>
          </Flex>
     
      </Box>

      <MooseLottie />
      </Flex>
      </Box>
      
    </InternalLayout>
  )
}
export const query = graphql`
  query NewsQuery($uid: String!){
    prismicNews(uid: {eq: $uid}) {
      _previewable
      uid
      url
      data {
        post_title {
          text
        }
        post_excerpt {
          text
        }
        post_content {
          text
          richText
        }
        featured_image {
          localFile {
            childImageSharp {
              gatsbyImageData(layout: FULL_WIDTH, placeholder: BLURRED)
            }
          }
          alt
        }
        date_posted(formatString: "MMMM, D YYYY")
      }
      first_publication_date(formatString: "MMMM, D YYYY")
    }
  }
`


export default withPrismicPreview(NewsTemplate)
